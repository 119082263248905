import { Link } from "react-router-dom"
import React from "react"
import Slider from "react-slick"
import "../slickslider.css"

function Info() {
	var settings = {
		dots: false,
		infinite: true,
		speed: 100,
		slidesToShow: 3,
		slidesToScroll: 1,
		swipeToSlide: true,
		initialSlide: 0,
		arrows: false,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					dots: false,
				},
			},
			{
				breakpoint: 480,
				settings: {
					autoplay: true,
					autoplaySpeed: 3000,
					slidesToShow: 1.04,
					dots: false,
					speed: 300,
					infinite: true,
					slidesToScroll: 1,
				},
			},
		],
	}
	function track(label: string) {
		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "footer", link: label })
		} catch (e) {}
	}

	return (
		<div>
			<div className="mx-auto mb-12 grid max-w-[1400px] grid-cols-1 p-6 pb-12  md:mt-12 md:grid-cols-2 md:pb-24">
				<div>
					{" "}
					<h2 className="mb-0 text-center font-Anton text-[48px] uppercase text-white lg:mt-0 lg:mb-0 lg:text-[72px]">HIGH PERFORMANCE</h2>
					<h3 className=" text-center font-Anton text-[32px] uppercase text-white lg:-mt-4 lg:mb-12 lg:text-[54px]">MAXIMALE GRÜNDLICHKEIT</h3>
					<div className=" mx-auto grid max-w-[90%] grid-cols-1 justify-items-center self-center text-white md:max-w-[75%]">
						<div className="md: mx-auto mt-6 mt-0 grid  grid-cols-2 items-center py-4 md:max-w-[80%] md:grid-cols-2 ">
							<div className="justify-self-end pr-6 md:pr-12">
								<img src={process.env.PUBLIC_URL + "/images/volvic/performance-step1.png"} className="" />
							</div>
							<p className="text-[12px] md:text-[14px]">6 diamantbeschichtete Klingen für eine gründliche & komfortable Rasur </p>
						</div>
						<div className="mx-auto grid grid-cols-2 items-center py-4 md:max-w-[80%]">
							<div className="justify-self-end pr-6 md:pr-12">
								<img src={process.env.PUBLIC_URL + "/images/volvic/performance-step2.png"} className="" />
							</div>
							<p className="text-[12px] md:text-[14px]">Gleitstreifen für optimale Gleitfähigkeit  </p>
						</div>
						<div className="mx-auto grid grid-cols-2 items-center  py-4 md:max-w-[80%] ">
							<div className="justify-self-end pr-6 md:pr-12">
								<img src={process.env.PUBLIC_URL + "/images/volvic/performance-step3.png"} className="" />
							</div>
							<p className="text-[12px] md:text-[14px]">Präzise Konturenklinge auf der Rückseite  </p>
						</div>
						<div className="mx-auto mb-12 grid grid-cols-2  items-center py-4 md:mb-0 md:max-w-[90%]">
							<div className="justify-self-end pr-[2.9rem] md:pr-[4.4rem]">
								<img src={process.env.PUBLIC_URL + "/images/volvic/performance-step4.png"} className="" />
							</div>
							<p className="text-[12px] md:text-[14px]">Klingen in Deutschland entwickelt</p>
						</div>
					</div>
				</div>
				<div className="grid justify-items-center self-center">
					<img src={process.env.PUBLIC_URL + "/images/volvic/product-image.png"} className="max-w-[60%]" />
				</div>
			</div>
		</div>
	)
}

export default Info
