import { useEffect, useState } from "react"
import { checkOrderCount } from "../env"
const RefundCounter = () => {
	const [orderDigitsSplit, setorderDigitsSplit] = useState([2, 5, 2, 0, 0])

	async function checkOrders() {
		setorderDigitsSplit(await checkOrderCount())
	}
	useEffect(() => {
		checkOrders()
	}, [])

	return (
		<div className="container mx-auto mt-12 mb-4 max-w-[1200px] text-center md:mt-24 md:mb-12">
			<h3 className="h3  stext-[26px] mb-12 text-white md:text-[32px]"> NOCH MÖGLICHE ERSTATTUNGEN</h3>
			<div className="mx-auto flex justify-center gap-1 self-center text-white drop-shadow-[0_4px_27px_rgba(0,0,0,0.25)]">
				{orderDigitsSplit.map((item, index) => (
					<div key={index}>
						<span className="refund-item bg-orange  p-4 py-1  font-Anton text-[42px] md:p-6">{item}</span>
					</div>
				))}
			</div>
		</div>
	)
}

export default RefundCounter
