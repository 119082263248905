import * as React from "react"
import { motion, useInView, useMotionValueEvent, useScroll, useTransform } from "framer-motion"

interface HeroProps {
	type?: any
}

const Hero: React.FC<HeroProps> = ({ type = "" }) => {
	return (
		<>
			{type === "promoover" ? (
				<section className=" lg:bg-center-bottom top-0 z-10 mx-auto w-full bg-black bg-cover bg-center md:mb-2">
					<h1 className="mx-auto mt-12 mb-2 max-w-[1000px] pl-6 text-center font-Anton text-[33px] text-white md:pl-0 md:text-center md:text-[62px]">
						DIESE AKTION IST LEIDER BEENDET!
					</h1>
				</section>
			) : type === "newsletter" ? (
				<section className=" lg:bg-center-bottom top-0 z-10 mx-auto w-full bg-black bg-cover bg-center md:mb-24">
					<h1 className="mx-auto mt-12 mb-2 max-w-[700px] pl-6 font-Anton text-[33px] text-white md:pl-0 md:text-center md:text-[62px]">Vielen Dank</h1>
					<p className="mt-0 mb-6 max-w-[80%] pl-6 font-Nunito text-[18px] text-orange md:mx-auto md:max-w-[700px] md:px-0 md:text-center md:text-[32px]">
						Deine Teilnahme wurde bestätigt.
					</p>
					<p className="mt-6 mb-6 max-w-[80%] pl-6 font-Nunito text-[18px] text-orange md:mx-auto md:max-w-[500px] md:px-0 md:text-center md:text-[32px]">
						Viele Grüße
						<br />
						Dein Wilkinson Sword Team
					</p>
				</section>
			) : (
				""
			)}

			{type === "promoover" ? (
				<section className="mt-12 text-center md:mt-0"></section>
			) : type === "newsletter" ? (
				<section className="mt-12 bg-orange md:mt-48"></section>
			) : (
				<section className="mx-auto mt-12  md:mt-12 ">
					<img src={process.env.PUBLIC_URL + "/images/volvic/hero-bg-desktop.png"} className="relative  left-20 mx-auto hidden md:block lg:max-w-[75%] xl:max-w-[50%]" />
					<img src={process.env.PUBLIC_URL + "/images/volvic/hero-bg-mobile.png"} className="relative mx-auto block pt-[40px] md:hidden" />
					<div className="relative  ml-[9%] hidden bg-orange md:ml-0 md:block md:text-center">
						<div className="mx-auto hidden  items-center pt-12 pb-20 md:grid  md:max-w-[670px] md:grid-cols-2 md:px-6 lg:max-w-[600px] lg:px-0 xl:max-w-[600px]">
							<h2 className="h2 text-left tracking-wide text-white md:text-[38px] lg:text-[46px] xl:text-[52px]">KAUFE EINEN WILKINSON 6</h2>
							<div className="relative">
								<img
									src={process.env.PUBLIC_URL + "/images/volvic/dm-logo.png"}
									className="absolute -right-[1rem] -top-[3.5rem] mx-auto hidden pt-[40px] md:block md:w-[60px] lg:w-[70px] xl:w-[75px]"
								/>
								<h3 className=" text-left text-white">RASIERER BEI DM</h3>
								<p className="text-left text-black md:max-w-[220px]">UND BEKOMME GANZ EINFACH 70% DES KAUFPREISES ERSTATTET.</p>
							</div>
						</div>
					</div>
					<div className="-mt-12  hidden items-center  justify-items-end md:grid">
						<a href="#teilnehmen" className=" z-24 relative top-4 bg-white px-4 py-2 font-Anton text-[1.5rem] uppercase text-black md:mx-auto md:w-[323px] md:text-[2.5rem]">
							Jetzt Teilnehmen!
						</a>
					</div>
				</section>
			)}
		</>
	)
}

export default Hero
