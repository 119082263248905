import { Link } from "react-router-dom"
import React from "react"

function Footer() {
	function track(label: string) {
		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "footer", link: label })
		} catch (e) {}
	}

	return (
		<footer className="relative items-center  bg-white  lg:z-[300]">
			<section>
				<div className="mx-auto mt-12 mb-12 max-w-[1400px] md:mb-4">
					<h2 className="font-Veener mx-auto mb-6 max-w-[400px] text-center font-Anton text-[24px] uppercase text-black md:text-[58px]">Folge uns AUCH AUF social media</h2>
					<div className="mx-auto mb-4 grid w-[50%] grid-cols-2 justify-items-center self-center lg:mb-24 lg:w-[20%] ">
						<a href="https://www.tiktok.com/@wilkinsonsword_de?lang=en" target="_blank" className="pointer underline">
							<img src={process.env.PUBLIC_URL + "/images/volvic/social/tiktok.svg"} alt="Tiktok" className="w-[50px] md:w-[75px]" />
						</a>
						<a href="https://www.instagram.com/wilkinson_men/" target="_blank" className="pointer underline">
							<img src={process.env.PUBLIC_URL + "/images/volvic/social/instagram.svg"} alt="Instagram" className="w-[50px] md:w-[75px]" />
						</a>
					</div>
				</div>
			</section>
			<section className="grid w-full items-end  bg-black bg-cover bg-bottom lg:mt-0">
				<div className="font-Veener flex flex-col items-center justify-center py-4  text-[12px] md:mt-8 lg:mt-0 lg:flex-row lg:text-[16px]">
					<Link to={"/teilnahmebedingungen"} className=" text-white lg:mr-12" onClick={() => track("Teilnahmebedingungen")}>
						Teilnahmebedingungen
					</Link>
					<a href={"https://wilkinsonsword.de/policies/privacy-policy"} target="_blank" className=" text-white lg:mr-12" onClick={() => track("Datenschutz")}>
						Datenschutz
					</a>
					<Link to={"/impressum"} className=" text-white lg:mr-12" onClick={() => track("Impressum")}>
						Impressum
					</Link>
				</div>
			</section>
		</footer>
	)
}

export default Footer
