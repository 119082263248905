import { useAutoAnimate } from "@formkit/auto-animate/react"
import React from "react"

interface InputProps {
	props?: any
	className?: string | null
	label?: string | null
	errMsg?: string
	hasError?: boolean
}

const ElemSelect: React.FC<InputProps> = ({ className, props, label, errMsg, hasError, children }) => {
	const [animationRef] = useAutoAnimate<HTMLDivElement>()
	return (
		<>
			<span className={"pointer-events-none mt-1 block  pb-1 pt-1 pl-2 text-[13px] text-white" + (hasError ? "  text-white" : " text-white ")}>{label}</span>
			<label className={"relative block " + (className || "")}>
				<select
					className={
						"  text-bold text-y outline- peer mb-2 w-full  appearance-none bg-white bg-[url('/src/bgimages/volvic/arrow-checkbox-green.svg')] bg-[160px_20px] bg-no-repeat py-3  px-4 shadow-inner " +
						(hasError ? "  js-formerror " : "")
					}
					{...props}
				>
					{children}
				</select>
				<div ref={animationRef}>{hasError && <div className="formErrorMsg mb-2 inline bg-whiteLight75  px-2 py-1 text-[12px] font-bold text-errorred">{errMsg}</div>}</div>
			</label>
		</>
	)
}

export default ElemSelect
