import * as React from "react"
import { useAutoAnimate } from "@formkit/auto-animate/react"

interface InputProps {
	props?: any
	className?: string | null
	label?: string | null
	errMsg?: string
	onClick?: any
	showLeftCount?: boolean
	hasError?: boolean
	suffix?: string
}

const ElemInput: React.FC<InputProps> = ({ className, props, label, onClick, errMsg, showLeftCount, hasError, suffix }) => {
	const [animationRef] = useAutoAnimate<HTMLDivElement>()
	return (
		<label className={"relative block " + (className || "")}>
			<span className={"pointer-events-none mt-1 block  pb-1 pt-1 pl-2 text-[13px] text-white"}>{label}</span>
			<input
				before={props.placeholder}
				className={
					(props.type === "date" ? "mb-2 h-[56px] uppercase text-black" : " peer ") +
					"mb-2 w-full appearance-none bg-white py-4  px-4 shadow-inner outline-0" +
					(hasError ? "  js-formerror lg:mt-0" : "") +
					(props.type === "date"
						? props.value === ""
							? "relative before:absolute before:left-4  before:capitalize before:text-[#ADB3BD] before:content-[attr(before)]  lg:before:hidden"
							: "relative uppercase"
						: "")
				}
				onClick={onClick}
				{...props}
			/>
			{suffix && <div className={"absolute bottom-2 right-3 text-lightgray" + (hasError ? " bottom-9" : " bottom-2")}>{suffix}</div>}
			{showLeftCount && props.maxLength && (
				<div className="absolute top-6 right-3 text-lightgray">
					{props.value.length}/{props.maxLength}
				</div>
			)}
			<div ref={animationRef}>{hasError && <div className="formErrorMsg mb-2 inline bg-whiteLight75 px-2 py-1 text-[12px] font-bold text-errorred">{errMsg}</div>}</div>
		</label>
	)
}

export default ElemInput

// https://play.tailwindcss.com/tKldzVn3U1
