import React, { useEffect } from "react"
import Header from "../components/Header"
import PreHero from "./PreHero"
import { Link } from "react-router-dom"
import Hero from "../components/home/Hero"
import Info from "../components/Info"

const Confirm: React.FC = () => {
	function track(label: string) {
		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "footer", link: label })
		} catch (e) {}
	}

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<>
			<div className="light bg-black">
				<Header />
				<Hero type="promoover" />
				<div className="mx-auto  mb-12 max-w-[900px] text-center lg:mt-6 lg:mb-2">
					<p className="mb-4 px-4 text-[18px] text-white md:text-[22px] ">
						Unsere Cashback-Aktion ist seit dem 01.12.2024 beendet. <br /> <br />
						Vielen Dank an alle, die an unserer Aktion teilgenommen haben. <br /> <br />
						Um keine Aktion mehr zu verpassen, besuche uns auf wilkinson.de und folge uns auf Instagram oder TikTok.
					</p>
				</div>
				<p className="mx-auto  mt-6 mb-12 max-w-[900px] px-4 text-center text-[24px] uppercase text-white md:text-[32px] lg:mt-24 lg:mb-12 ">
					Jetzt noch zusätzlich mit dem Kassenbon an dem laufenden Gewinnspiel teilnehmen!
				</p>
				<div className="mb-12 pb-12 text-center lg:mt-4 lg:pb-24">
					<a
						href="https://wilkinsonsword.de/pages/wilkinson-6-gewinnspiel?"
						target="_blank"
						className="btn mx-auto mt-24 mb-24 font-Anton text-[24px] uppercase text-white md:text-[42px]"
					>
						{" "}
						Zum Gewinnspiel
					</a>
				</div>
				<div className="bg-orange">
					<img src={process.env.PUBLIC_URL + "/images/volvic/gewinnspiel/hero-bg-desktop.png"} alt="Gewinnpiel" className="mx-auto hidden max-w-[75%] py-6 md:block" />
					<img src={process.env.PUBLIC_URL + "/images/volvic/gewinnspiel/hero-bg-mobile.png"} alt="Gewinnpiel" className="mx-auto max-w-[100%] py-2 md:hidden" />
				</div>
			</div>
		</>
	)
}
export default Confirm
