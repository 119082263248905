import React, { useEffect, useState } from "react"
import DocumentMeta from "react-document-meta"
import Form from "../components/Forms/Form"
import Sogehts from "../components/home/Sogehts"
import Header from "../components/Header"
import Hero from "../components/home/Hero"
import Info from "../components/Info"
import { getPromoLimit, getWeeklyLimit } from "../env"
import { useNavigate } from "react-router-dom"

const Home: React.FC = () => {
	// SEO Meta Tags
	const [limitReached, setLimitReached] = useState<boolean>(false)
	const [isApiError, setApiError] = useState<boolean>(false)
	const navigate = useNavigate()

	const meta = {
		title: "Wilkinson 6 - 70% Cashback!",
		description: "70% Cashback, Wilkinson 6, Rasierer, Geld zurück, Wilkinson Sword, dm",
		meta: {
			name: {
				robots: "noindex, nofollow",
			},
		},
	}
	async function getOrderLimits() {
		let maxLimit = await getWeeklyLimit()
		if (typeof maxLimit !== "boolean") {
			setApiError(true)
		} else {
			setLimitReached(maxLimit)
			let overallLimit = await getPromoLimit()
			if (overallLimit) {
				navigate("/promoover")
			}
		}
	}

	useEffect(() => {
		getOrderLimits()
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])
	return (
		<DocumentMeta {...meta}>
			{isApiError ? (
				<div className="bg-black">
					<Header />
					<Hero />
					<Sogehts type="apierror" />
				</div>
			) : !limitReached ? (
				<div className="bg-black">
					<Header />
					<Hero />
					<Sogehts type="start" />
					<Form />
					<Info />
				</div>
			) : (
				<div className="bg-black">
					<Header />
					<Hero />
					<Sogehts type="errorLimit" />
				</div>
			)}
		</DocumentMeta>
	)
}
export default Home
