import React, { createContext, useReducer } from "react"
import Reducer from "./reducer"

const initialState = {
	salutation: 4,
	firstname: "",
	surname: "",
	email: "",
	terms: "",
	receiptReference: null,
	birthday: "",
	receiptFileName: null,
	iban: "",
	bic: "",
	price: "",
	accountHolder: "",
	newsletter: "",
}

const Store = ({ children }) => {
	const [globalState, dispatch] = useReducer(Reducer, initialState)
	return <Context.Provider value={{ globalState, dispatch }}>{children}</Context.Provider>
}
export const Context = createContext<{
	globalState
	dispatch: React.Dispatch<any>
}>({
	globalState: initialState,
	dispatch: () => {},
})
export default Store
