import React, { useEffect } from "react"
import Header from "../components/Header"
import { Link, useNavigate } from "react-router-dom"

const Error: React.FC = () => {
	const navigate = useNavigate()
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<>
			<Header />
			<div className="relative z-0 px-4 pt-0 pb-16 text-center text-xl text-white">
				<h2 className="mb-4 mt-12 text-[22px] font-bold uppercase leading-[26px] text-white lg:mb-0 lg:mt-24 lg:text-[52px] lg:leading-[58px]">
					Hier scheint etwas schief gelaufen zu sein ....
				</h2>
			</div>
			<div className="py-10 pt-1 text-center text-xl text-red">
				<button onClick={() => navigate(-1)} className="btn btn-hero  mt-2 rounded bg-white px-12 py-4 text-red">
					Zurück
				</button>
			</div>
		</>
	)
}
export default Error
