import React from "react"
interface SpinnerProps {
	bgcolor?: string
}

const Spinner: React.FC<SpinnerProps> = ({ bgcolor }) => {
	return (
		<svg className="ml-0 inline-block animate-spinner " viewBox="0 0 50 50" width="30" height="30">
			<circle className="animate-spinnerDash stroke-white" cx={25} cy={25} r={20} fill="none" strokeLinecap="round" stroke={bgcolor ? "#DF7E2D" : "#DF7E2D"} strokeWidth={5} />
		</svg>
	)
}

export default Spinner
