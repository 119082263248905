import * as React from "react"
import { useState } from "react"
import { useAutoAnimate } from "@formkit/auto-animate/react"
import MobileMenu from "./MobileMenu"
import { Link } from "react-router-dom"

const Header = ({ isPromoOver = false, showPrescreen = false, type = "" }) => {
	const [showMenu, setShowMenu] = useState(false)
	const [animationRef] = useAutoAnimate<HTMLDivElement>()

	function scrollToAnchor(anchor) {
		setTimeout(() => {
			try {
				const doc: any = document
				const { top } = doc.querySelector(".js-" + anchor).getBoundingClientRect()

				window.scrollTo({
					top: top + window.scrollY - 220,
					left: 0,
					behavior: "smooth",
				})

				try {
					// eslint-disable-next-line
					let dataLayer = window["dataLayer"] || []
					dataLayer.push({ event: "header", link: anchor })
				} catch (e) {}
			} catch (error) {}
		}, 300)
	}

	return (
		<>
			<div ref={animationRef}>{showMenu && <MobileMenu setShowMenu={setShowMenu} isPromoOver={isPromoOver} scrollToAnchor={scrollToAnchor} />}</div>
			<header className="">
				<div className="mx-auto grid max-w-[1400px] grid-cols-[1fr_1fr_1fr] items-center justify-items-center self-center justify-self-center pt-6 md:pt-24  lg:grid-cols-1">
					<div className="lg:hidden"></div>
					<div className="grid items-center justify-items-center pt-[25px] md:pt-0">
						<Link to="/">
							<img src={process.env.PUBLIC_URL + "/images/volvic/logo.png"} className="z-2 relative mx-auto max-w-[60%] lg:max-w-[60%]" />
						</Link>
					</div>
					<div className="block justify-self-end lg:hidden">
						<img src={process.env.PUBLIC_URL + "/images/volvic/burger.svg"} onClick={() => setShowMenu(true)} className="z-2 relative mr-4 max-w-[70%] lg:max-w-[65%]" />
					</div>
					<div className="hidden">
						<Link
							to={process.env.PUBLIC_URL + "/teilnahmebedingungen"}
							className="header-item font-Veener mr-12 text-lightgreen hover:text-red"
							onClick={() => {
								try {
									// eslint-disable-next-line
									let dataLayer = window["dataLayer"] || []
									dataLayer.push({ event: "header", link: "teilnahmebedingungen" })
								} catch (e) {}
								return setShowMenu(false)
							}}
						>
							Teilnahmebedingungen
						</Link>
						<Link
							to={process.env.PUBLIC_URL + "/faq"}
							className="header-item font-Veener mr-12 text-lightgreen hover:text-red"
							onClick={() => {
								try {
									// eslint-disable-next-line
									let dataLayer = window["dataLayer"] || []
									dataLayer.push({ event: "header", link: "faq" })
								} catch (e) {}
								return setShowMenu(false)
							}}
						>
							FAQS
						</Link>
						<Link
							to={process.env.PUBLIC_URL + "/kontakt"}
							className="header-item font-Veener mr-4 text-lightgreen hover:text-red"
							onClick={() => {
								try {
									// eslint-disable-next-line
									let dataLayer = window["dataLayer"] || []
									dataLayer.push({ event: "header", link: "kontakt" })
								} catch (e) {}
								return setShowMenu(false)
							}}
						>
							Kontakt
						</Link>
						<a
							href={"https://wilkinsonsword.de/policies/privacy-policy"}
							target="_blank"
							className="header-item font-Veener mr-4 ml-6 text-lightgreen hover:text-red"
							onClick={() => {
								try {
									// eslint-disable-next-line
									let dataLayer = window["dataLayer"] || []
									dataLayer.push({ event: "header", link: "kontakt" })
								} catch (e) {}
								return setShowMenu(false)
							}}
						>
							Datenschutzbestimmungen
						</a>
					</div>
				</div>
			</header>
		</>
	)
}

export default Header
