import React, { useEffect } from "react"
import Header from "../components/Header"
import PreHero from "./PreHero"
import { Link } from "react-router-dom"
import Hero from "../components/home/Hero"

const Confirm: React.FC = () => {
	function track(label: string) {
		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "footer", link: label })
		} catch (e) {}
	}

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<>
			<div className="light bg-black ">
				<Header type="promoover" />
				<div className="bg-norepeat  relative z-[1200] w-full bg-black bg-cover bg-center bg-repeat-y pt-[0px] lg:h-[700px] lg:pt-[0px]">
					<div className=" mx-auto max-w-[1400px] pt-12 text-center text-white lg:pt-6">
						<h3 className="mb-12 text-[24px]">VIELEN DANK FÜR DIE TEILNAHME AN UNSERER CASHBACK-AKTION.</h3>
						<p className="mb-12 px-4 md:px-0">Die Daten wurden erfolgreich übermittelt.</p>
						<p className="mb-12 px-4 md:px-0">Du erhältst per E-Mail eine Eingangsbestätigung. Anschließend werden wir die Einhaltung der Teilnahmevoraussetzungen prüfen.</p>
						<p className="mb-12 px-4 md:px-0 lg:mb-14">
							Viele Grüße
							<br />
							Dein Wilkinson Sword Team
						</p>

						<p className="mx-auto  mt-6 mb-12 max-w-[900px] px-4 text-center text-[24px] uppercase text-white md:text-[32px] lg:mt-24 lg:mb-12 ">
							Jetzt noch zusätzlich mit dem Kassenbon an dem laufenden Gewinnspiel teilnehmen!
						</p>
						<div className="mb-12 pb-12 text-center lg:mt-4 lg:pb-24">
							<a
								href="https://wilkinsonsword.de/pages/wilkinson-6-gewinnspiel?"
								target="_blank"
								className="btn mx-auto mt-24 mb-24 font-Anton text-[24px] uppercase text-white md:text-[42px]"
							>
								{" "}
								Zum Gewinnspiel
							</a>
						</div>
					</div>
				</div>
				<div className="bg-orange">
					<img src={process.env.PUBLIC_URL + "/images/volvic/gewinnspiel/hero-bg-desktop.png"} alt="Gewinnpiel" className="mx-auto hidden max-w-[75%] py-6 md:block" />
					<img src={process.env.PUBLIC_URL + "/images/volvic/gewinnspiel/hero-bg-mobile.png"} alt="Gewinnpiel" className="mx-auto max-w-[100%] py-2 md:hidden" />
				</div>
			</div>
		</>
	)
}
export default Confirm
