import React from "react"
import { useContext } from "react"
import { Context } from "../../store/store"
import ElemInput from "../elements/Input"
import ElemSelect from "../elements/Select"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
interface PersonProps {
	formElementChanged: Function
	validation: Object
}
const Person: React.FC<PersonProps> = ({ formElementChanged, validation }) => {
	const { globalState } = useContext(Context)

	return (
		<section className="mx-auto max-w-[900px] px-6 md:px-0">
			<div className="mx-auto grid grid-cols-1">
				<ElemSelect
					label={"Anrede"}
					props={{
						type: "text",
						name: "salutation",
						value: globalState.salutation,
						minLength: 1,
						onChange: e => formElementChanged(e, "salutation"),
					}}
					hasError={validation["invalid_salutation"] ? true : false}
					className={"max-w-[200px] " + (globalState.salutation === 4 ? "text-[#ADB3BD]" : "text-black")}
					errMsg={validation["invalid_salutation_errMsg"] || "Bitte Vornamen eingeben."}
				>
					<option value="4" selected>
						Anrede
					</option>
					<option value="1">Herr</option>
					<option value="2">Frau</option>
					<option value="3">Divers</option>
				</ElemSelect>
			</div>
			<div className="mx-auto grid  grid-cols-1 items-start lg:grid-cols-[1fr_1fr] lg:gap-6">
				<ElemInput
					label={"Dein Vorname*"}
					props={{
						type: "text",
						name: "firstname",
						value: globalState.firstname,
						required: "required",
						minLength: 1,
						placeholder: "Dein Vorname*",
						onChange: e => formElementChanged(e, "firstname"),
					}}
					hasError={validation["invalid_firstname"] ? true : false}
					className=""
					errMsg={validation["invalid_firstname_errMsg"] || "Bitte gib deinen Vornamen an."}
				/>
				<ElemInput
					label={"Dein Nachname*"}
					props={{
						type: "text",
						name: "surname",
						value: globalState.surname,
						required: "required",
						minLength: 1,
						placeholder: "Dein Nachname*",
						onChange: e => formElementChanged(e, "surname"),
					}}
					hasError={validation["invalid_surname"] ? true : false}
					className="col-span-2 lg:col-span-1"
					errMsg={validation["invalid_surname_errMsg"] || "Bitte gib deinen Nachnamen an."}
				/>
			</div>
			<div className="mx-auto grid  grid-cols-1 items-start lg:grid-cols-[1fr_1fr] lg:gap-6">
				<ElemInput
					label={"Dein Geburtsdatum*"}
					props={{
						type: "date",
						name: "birthday",
						value: globalState.birthday,
						required: "required",
						minLength: 1,
						placeholder: "Dein Geburtsdatum*",
						onChange: e => formElementChanged(e, "birthday"),
					}}
					hasError={validation["invalid_birthday"] ? true : false}
					className="col-span-2 lg:col-span-1"
					errMsg={validation["invalid_birthday_errMsg"] || "Bitte gib dein Geburtsdatum an."}
				/>
				<div className="">
					<ElemInput
						label={"Deine E-Mail-Adresse*"}
						props={{
							type: "text",
							name: "email",
							value: globalState.email,
							required: "required",
							minLength: 1,
							placeholder: "Deine E-Mail-Adresse*",
							onChange: e => formElementChanged(e, "email"),
						}}
						hasError={validation["invalid_email"] ? true : false}
						className="col-span-2 lg:col-span-1"
						errMsg={validation["invalid_email_errMsg"] || "Bitte gib deine E-Mail-Adresse ein."}
					/>
				</div>
			</div>
		</section>
	)
}

export default Person
