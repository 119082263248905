import React, { useEffect } from "react"
import DocumentMeta from "react-document-meta"
import { Link, useNavigate } from "react-router-dom"
import GreenSection from "../components/elements/GreenSection"
import RosaSection from "../components/elements/BeigeSection"
import Header from "../components/Header"

const Contact: React.FC = () => {
	const navigate = useNavigate()

	// SEO Meta Tags

	const meta = {
		// title: "Mit merci zum Muttertag filmreif Danke sagen!",
		// canonical: "https://arvato-hawaiian-tropic-2023.de/",
		// meta: {
		// 	name: {
		// 		robots: "noindex, nofollow",
		// 	},
		// },
	}

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<DocumentMeta {...meta}>
			<div className="light bg-black">
				<Header type="logo" />
				<div className="container mx-auto mt-0 max-w-[400px] py-8 px-4  text-center text-white ">
					<h2 className="h2 font-Veener mb-8 mt-12 text-2xl text-orange lg:mt-12">Kontakt</h2>
					<p className=" mb-4">Du hast eine Frage, Anregungen oder Kritik?</p>
					<p className=" mb-4">Dann nimm gerne Kontakt mit uns auf. Wir freuen uns über Deine Nachricht!</p>
					<p className=" mb-4">
						<svg className="mr-2 inline-block w-5" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="EmailIcon">
							<path fill="#ffffff" d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4-8 5-8-5V6l8 5 8-5v2z" />
						</svg>
						<a
							href="mailto:info@volvic-gratis-testen.de"
							className="underline"
							onClick={() => {
								try {
									// eslint-disable-next-line
									let dataLayer = window["dataLayer"] || []
									dataLayer.push({ event: "contact", link: "mail" })
								} catch (e) {}
							}}
						>
							info@volvic-gratis-testen.de
						</a>
					</p>
					<p className=" mb-0">
						Allgemeine Informationen zur Aktion findest du außerdem in den{" "}
						<Link
							to="/faq"
							target={"_blank"}
							onClick={() => {
								try {
									// eslint-disable-next-line
									let dataLayer = window["dataLayer"] || []
									dataLayer.push({ event: "contact", link: "faq" })
								} catch (e) {}
							}}
						>
							<span className="underline">FAQ</span>
						</Link>
						.
					</p>
				</div>
				<div className="py-10 pt-5 text-center text-xl text-white">
					<button onClick={() => navigate(-1)} className="btn btn-hero  mt-2  bg-orange px-12 py-4 text-white">
						Zurück
					</button>
				</div>
			</div>
		</DocumentMeta>
	)
}
export default Contact
