import { useContext, useState } from "react"
import React from "react"
import { Context } from "../../store/store"
import Popup from "../elements/Popup"
import ReceiptUpload from "./ReceiptUpload"
import { useAutoAnimate } from "@formkit/auto-animate/react"

const Receipt = () => {
	const { dispatch } = useContext(Context)
	const [showSample, setShowSample] = useState<boolean>(false)
	const [animationRef] = useAutoAnimate<HTMLDivElement>()

	return (
		<>
			<section className="js-mitmachen relative mb-12 bg-black pt-8 sm:px-4 lg:mb-12">
				<div className="container text-center">
					<h2 className="font-Veener mb-6 mt-2 text-center font-Anton text-[36px] uppercase text-white lg:mt-12 lg:mb-2 lg:text-[58px]">Kassenbon hochladen</h2>
					<p className="mx-auto mb-12 px-4 text-center text-[16px] leading-[22px] text-white md:px-0 lg:max-w-[65%] lg:text-[22px] lg:leading-[37px]">
						Achte darauf, dass der Kaufbeleg vollständig ist und Datum, Einkaufsstätte, Aktionsprodukt, Preis sowie Bonsumme gut lesbar sind.
					</p>
					<div className="p-1 sm:p-4">
						<div className="arrow-white mx-auto max-w-[300px] rounded-lg border-[2px] border-dashed border-white bg-orange px-2 py-6 md:max-w-[500px] lg:py-16">
							<ReceiptUpload dispatch={dispatch} />
						</div>
					</div>
					<p
						className="cursor-pointer text-white underline"
						onClick={() => {
							setShowSample(true)
						}}
					>
						{" "}
						Beispiel ansehen
					</p>
				</div>

				<div ref={animationRef}>
					{showSample && (
						<Popup close={() => setShowSample(false)}>
							<img src={process.env.PUBLIC_URL + "/images/volvic/Beispielkassenbon.jpg"} alt="" className="mx-auto block h-auto lg:hidden" />
							<img src={process.env.PUBLIC_URL + "/images/volvic/Beispielkassenbon-desktop.jpg"} alt="" className=" mx-auto hidden h-auto max-w-[75%] lg:block" />
						</Popup>
					)}
				</div>
			</section>
		</>
	)
}

export default Receipt
