import * as React from "react"
import { useEffect, useState } from "react"
import RosaSection from "../components/elements/BeigeSection"
import { Link, useSearchParams } from "react-router-dom"
import axios from "axios"
import { getEnvConfig } from "../env"
import Hero from "../components/home/Hero"
import Header from "../components/Header"

function NewsletterConfirm() {
	const env = getEnvConfig()
	const [searchParams] = useSearchParams()
	const confirmationid = searchParams.get("hash")
	const [confirmSuccess, setConfirmSuccess] = useState(false)
	const [errorMessage, setErrorMessage] = useState("")

	function track(label: string) {
		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "footer", link: label })
		} catch (e) {}
	}
	const confirmNewsletter = () => {
		axios
			.post(env.apibase + "confirmNewsletter.php?hash=" + confirmationid)
			.then(res => {
				if (res.data.doubleOptInConfirmed) {
					setConfirmSuccess(true)
				}
			})
			.catch(error => {
				setErrorMessage("Deine Registrierung war bereits erfolgreich")
				setConfirmSuccess(false)
			})
	}

	useEffect(() => {
		confirmNewsletter()
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<div className="bg-black pb-12">
			<Header />
			<Hero type="newsletter" />
		</div>
	)
}
export default NewsletterConfirm
