export enum ERRORTYPES {
	FORMVALIDATION = "Bitte fülle alle Felder korrekt aus.",
	INVALID = "Leider stimmt etwas mit Deinem Kassenbon nicht. Wir können keinen Kauf eines teilnehmenden Produktes erkennen.",
	RECEIPT_UPLOAD_ERROR = "Der hochgeladene Kassenbon wurde bereits verwendet.",
	RETRY = "Du hast Deinen Kassenbon bereits verwendet. Bitte nutze einen anderen Kassenbon. ",
	FILESIZE = "Dein ausgewählter Kassenbon entspricht nicht den Vorgaben. JPG, PNG oder PDF / max. 8 MB",
	TERMSUPLOAD = "Du hast noch keinen Kassenbon hochgeladen oder die Teilnahmebedingungen noch nicht bestätigt. ",
	DOUBBLED = "Du hast Deinen Kassenbon bereits verwendet.",
	MAXTRIES = "Du hast heute bereits zu häufig an unserem Gewinnspiel teilgenommen. Bitte probiere es morgen noch einmal.",
	SERVER = "Bei der Übertragung deiner Daten ist ein Fehler aufgetreten. Bitte versuche es erneut. Sollten die Probleme weiterhin bestehen, kannst du dich an unser Service-Team wenden. Du erreichst uns per E-Mail unter cashback@wilkinson6-gewinnspiel.de. Bitte entschuldige die Unannehmlichkeiten.",
	UPLOADSMISSING = "Bitte lade einen Rechnungsbeleg hoch.",
	WRONGQUESTION1 = "Das 'Sonstiges'-Textfeld für die Frage 1 darf nicht leer sein, wenn das Kästchen 'Sonstiges' aktiviert ist.",
	WRONGQUESTION2 = "Das 'Sonstiges'-Textfeld für die Frage 2 darf nicht leer sein, wenn das Kästchen 'Sonstiges' aktiviert ist.",
}

export const MAX_PRODUCT_PRICE = 5.0
