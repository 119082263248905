import React, { useEffect } from "react"
import DocumentMeta from "react-document-meta"
import { useNavigate, Link } from "react-router-dom"
import RosaSection from "../components/elements/BeigeSection"
import Header from "../components/Header"
const Imprint: React.FC = () => {
	const navigate = useNavigate()

	// SEO Meta Tags

	const meta = {
		// title: "Mit merci zum Muttertag filmreif Danke sagen!",
		// canonical: "https://arvato-hawaiian-tropic-2023.de/",
		// meta: {
		// 	name: {
		// 		robots: "noindex, nofollow",
		// 	},
		// },
	}

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<DocumentMeta {...meta}>
			<div className="light bg-black">
				<Header type="logo" />
				<div className="container mx-auto max-w-[800px] py-8 px-4 text-white">
					<h2 className="h2 font-Veener mb-8 mt-12 text-center text-2xl text-orange lg:mt-12">Impressum</h2>
					<h2 className="mt-8 mb-2 text-[20px] font-bold font-bold leading-tight">Verantwortlich</h2>
					<p className=" mb-4">
						Wilkinson Sword GmbH
						<br />
						<br /> Handelsregister: HRB 16753 <br />
						Registergericht: Amtsgericht Wuppertal
						<br />
						<br />
						Ust-ID gemäß §27a Umsatzsteuergesetz: DE813978227
						<br />
						<br />
						Geschäftsführer: Thomas Lummerich, Jochen Schmitz, LaTanya Langley, Daniel Joseph Sullivan
					</p>
				</div>
				<div className=" py-10 pt-5 text-center text-xl text-white">
					<Link to="/" className="btn btn-hero mt-8 rounded bg-white px-12 py-4 text-white">
						Zurück
					</Link>
				</div>
			</div>
		</DocumentMeta>
	)
}
export default Imprint
